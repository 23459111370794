import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

import Button from "./theme/Button";
import Checkbox from "./theme/Checkbox";

const breakpoints = createBreakpoints({
  xs: "520px",
  sm: "992px",
  md: "1281px",
  lg: "1367px",
  xl: "1537px",
});

const theme = extendTheme({
  colors: {
    primary: {
      500: "#E61E28",
    },
    orange: {
      200: "#E2BE71",
      400: "#E8931B",
      800: "#E75822",
    },
    brown: {
      500: "#9F5E4C",
    },
    yellow: {
      200: "#B4C525",
      400: "#E9CD15",
      500: "#E9BF14",
      700: "#FBC700",
    },
    green: {
      50: "#ddecd6",
      100: "#528B30",
      200: "#5DB758",
      400: "#80BD35",
      500: "#4BB545",
    },
    blue: {
      200: "#32A4A0",
      300: "#1E9BD7",
      500: "#6F9BB7",
      800: "#005AAA",
    },
    purple: {
      500: "#7D4196",
    },
    pink: {
      500: "#C83C96",
    },
    black: {
      100: "#182322",
      200: "#243533",
      300: "#525758",
      400: "#707070",
      500: "#485654",
      600: "#95979A",
      800: "#B5B5B8",
      900: "#C2C8C8",
    },
    white: {
      200: "#F5F6F8",
      300: "#F5F5F5",
      400: "#F8F8F9",
      500: "#FFFFFF",
    },
    grey: {
      100: "#E2E5E5",
      200: "#E8ECEC",
      300: "#BCC3C4",
      400: "#ADC7CE",
      500: "#8F9796",
      600: "#CCCCCC",
      700: "#6B7675",
    },
  },
  fonts: {
    heading: "Times",
    body: "Arial",
  },
  fontSizes: {
    heading1: "1.375rem",
    heading2: "1.125rem",
    heading3: "0.9375rem",
    base: "1rem",
    md: "0.875rem",
    small: "0.75rem",
    xs: "0.55rem",
  },
  components: {
    Button,
    Checkbox,
    Divider: {
      baseStyle: {
        borderColor: "black.200",
      },
    },
  },
  styles: {
    global: () => ({
      body: {
        fontFamily: "body",
        lineHeight: "base",
        bg: "white.400",
      },
    }),
  },
  breakpoints,
});

export default theme;
