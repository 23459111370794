import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
  baseStyle: {
    padding: "0.5rem 1rem",
    fontWeight: "400",
    _hover: {
      filter: "brightness(115%)",
    },
  },
  sizes: {
    md: {
      w: "fit-content",
      h: "fit-content",
      minWidth: "7rem",
    },
  },
  variants: {
    solidPrimary: {
      backgroundColor: "primary.500",
      color: "white.500",
      fontSize: "small",
      fontFamily: "Arial",
      fontWeight: "bold",
      borderRadius: "1rem",
      _disabled: {
        backgroundColor: "black.900",
      },
      _hover: {
        _disabled: { backgroundColor: "black.600" },
      },
    },
    outlinePrimary: {
      backgroundColor: "transparent",
      color: "primary.500",
      fontSize: "small",
      fontFamily: "Arial",
      fontWeight: "600",
      border: "1px solid",
      borderColor: "primary.500",
      _hover: {
        backgroundColor: "primary.500",
        color: "white.500",
        _disabled: { color: "primary.500" },
      },
    },
  },
  defaultProps: {
    size: "md",
  },
};

export default componentOverride;
