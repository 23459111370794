import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

interface IProps {}

const Loading: React.FC<IProps> = (props) => {
  return (
    <Flex w="100%" h="100vh" justify="center" alignItems="center">
      <Spinner color="primary.500" size="lg" />
    </Flex>
  );
};
export default Loading;
