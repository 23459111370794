import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
  baseStyle: {
    control: {
      borderColor: "black.600",
      _checked: {
        bg: "black.600",
        borderColor: "black.600",
        _hover: {
          backgroundColor: "primary.500",
          borderColor: "transparent",
        },
      },
    },
    label: {
      color: "black.200",
    },
  },
  variants: {
    primary: {
      control: {
        _checked: {
          bg: "primary.500",
          borderColor: "primary.500",
        },
      },
    },
  },
  sizes: {
    md: {
      label: {
        fontSize: "small",
      },
    },
  },
  defaultProps: {
    size: "md",
  },
};

export default componentOverride;
