import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import theme from "./config/theme";
import "./App.css";
import Loading from "./modules/common/Loading";

const Landing = React.lazy(() => import("./modules/Landing"));
const Defs = React.lazy(() => import("./modules/Defs"));
const Decarb = React.lazy(() => import("./modules/Policies"));
const Frame = React.lazy(() => import("./modules/common/Frame"));
const TextFrame = React.lazy(() => import("./modules/common/TextFrame"));

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                <Frame>
                  <TextFrame>
                    <Landing />
                  </TextFrame>
                </Frame>
              </React.Suspense>
            }
          />
          <Route
            path="defs"
            element={
              <React.Suspense fallback={<Loading />}>
                <Frame>
                  <TextFrame>
                    <Defs />
                  </TextFrame>
                </Frame>
              </React.Suspense>
            }
          />
          <Route
            path="policies"
            element={
              <React.Suspense fallback={<Loading />}>
                <Frame>
                  <Decarb />
                </Frame>
              </React.Suspense>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
